import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAllLettersColor, setConfigValue } from '../../../redux/slices/config';
import { repaintAllLetters } from '../../../utils/ColoredLetters';
import ColorButton from '../../ColorButton';

import { BiFontColor } from 'react-icons/bi';
import { IoColorFillOutline } from 'react-icons/io5';
import KeyboardPopup from '../../popups/KeyboardPopup';
import { getTranslation } from '../../../i18n/getTranslation';

const ColorSettings = () => {
	const [lettersActive, setLettersActive] = useState(false);
	const { backgroundColor } = useSelector(state => state.config);
	const dispatch = useDispatch();

	const onBgColorChange = color => {
		dispatch(setConfigValue({ field: 'backgroundColor', value: color.hex }));
	};

	return (
		<>
			<div className='item bg-color'>
				<ColorButton onColorChange={onBgColorChange} currentLetterColor={backgroundColor} changeColor={false}>
					<IoColorFillOutline color='#26334E' size={50} />
					<p>{getTranslation('bg-color')}</p>
				</ColorButton>
			</div>
			<div className='item icon-button letter-colors' onClick={() => setLettersActive(true)}>
				<BiFontColor color='#26334E' size={50} />
				<p>{getTranslation('color-letters')}</p>
			</div>
			<KeyboardPopup open={lettersActive} setOpen={setLettersActive} />
		</>
	);
};

export default ColorSettings;
