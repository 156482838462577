import esPictograms from './pictograms/ES-pictograms-map.json';
import enPictograms from './pictograms/EN-pictograms-map.json';
import itPictograms from './pictograms/IT-pictograms-map.json';
import frPictograms from './pictograms/FR-pictograms-map.json';
import caPictograms from './pictograms/CA-pictograms-map.json';
import dePictograms from './pictograms/DE-pictograms-map.json';
import euPictograms from './pictograms/EU-pictograms-map.json';

export const getWordPictogramId = (word, lang) => {
	const pictogramLangMap = {
		en: enPictograms,
		es: esPictograms,
		it: itPictograms,
		fr: frPictograms,
		ca: caPictograms,
		de: dePictograms,
		eu: euPictograms,
	};

	return pictogramLangMap[lang][word];
};
