import { AnimatePresence, motion } from 'framer-motion';

const Popup = props => {
	const { close, visible, classes, children } = props;
	return (
		<AnimatePresence>
			{visible && (
				<div className='popup-container flex center column'>
					<motion.div
						key='popup-bg'
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.2 }}
						className='background'
						onClick={close}
					/>
					<motion.div
						key='popup-content'
						initial={{ transform: 'translateY(-30%)', opacity: 0 }}
						animate={{ transform: 'translateY(0%)', opacity: 1 }}
						transition={{ duration: 0.2 }}
						exit={{ opacity: 0 }}
						className={`popup flex column ${classes}`}
					>
						{children}
					</motion.div>
				</div>
			)}
		</AnimatePresence>
	);
};

export default Popup;
