import { useEffect, useState } from 'react';
import Joyride from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslation } from '../i18n/getTranslation';

import { setCurrentTab, setTutorialStarted, setViewMobileWelcome } from '../redux/slices/components';

const BeaconComponent = ({ onClick, onMouseEnter }) => {
	return <div className='beacon-component' onClick={onClick} onMouseEnter={onMouseEnter}></div>;
};

const TooltipComponent = ({
	continuous,
	index,
	step,
	backProps,
	closeProps,
	primaryProps,
	tooltipProps,
	newTab,
	prevTab = 0,
}) => {
	const dispatch = useDispatch();

	const goToFirstTab = () => dispatch(setCurrentTab(0));
	const goToPrevTab = () => dispatch(setCurrentTab(prevTab));
	const goToNewTab = () => dispatch(setCurrentTab(newTab));

	return (
		<div className='tutorial-tooltip-container' {...tooltipProps}>
			{step.title && <p className='title'>{step.title}</p>}
			<div className='text'>{step.content}</div>
			<div className='buttons'>
				{index > 0 && (
					<button
						className='btn btn-back'
						{...backProps}
						onClick={e => {
							goToPrevTab();
							setTimeout(() => backProps.onClick(e), 20);
						}}
					>
						<p id='back'>{getTranslation('previous')}</p>
					</button>
				)}
				<button
					className='btn btn-close'
					{...closeProps}
					onClick={e => {
						closeProps.onClick(e);
						dispatch(setTutorialStarted(false));
					}}
				>
					<p id='close'>{getTranslation('close')}</p>
				</button>
				{continuous && (
					<button
						className='btn btn-next'
						{...primaryProps}
						onClick={e => {
							if (newTab) goToNewTab();
							setTimeout(() => primaryProps.onClick(e), 20);
						}}
					>
						<p id='next'>{getTranslation('next')}</p>
					</button>
				)}
			</div>
		</div>
	);
};

const Tutorial = props => {
	const dispatch = useDispatch();
	const {
		tutorial: { started },
	} = useSelector(state => state.components);

	const { firstTime } = useSelector(state => state.components);

	const [helpers, setHelpers] = useState();

	const handleJoyrideCallback = data => {
		if (data.action === 'reset' && helpers) {
			console.log('finished');
			dispatch(setTutorialStarted(false));
		}
	};

	useEffect(() => {
		if (started === false && helpers && typeof helpers.reset === 'function') {
			if (props.isMobile()) dispatch(setViewMobileWelcome(true));
			helpers.reset();
		}
	}, [started]);

	return (
		<Joyride
			steps={steps}
			continuous
			callback={handleJoyrideCallback}
			tooltipComponent={TooltipComponent}
			beaconComponent={BeaconComponent}
			run={started}
			getHelpers={h => setHelpers(h)}
			disableScrolling
		/>
	);
};

const steps = [
	{
		target: '.topbar .text-config',
		title: getTranslation('title-text-config'),
		content: getTranslation('text-text-config'),
		disableBeacon: true,
	},
	{
		target: '.topbar .align',
		title: getTranslation('title-text-align'),
		content: getTranslation('text-text-align'),
	},
	{
		target: '.topbar .tts',
		title: getTranslation('title-read-alloud'),
		content: getTranslation('text-read-alloud'),
		tooltipComponent: props => <TooltipComponent prevTab={0} newTab={1} {...props} />,
	},
	{
		target: '.topbar .bg-color',
		title: getTranslation('bg-color'),
		content: getTranslation('text-bg-color'),
		tooltipComponent: props => <TooltipComponent prevTab={0} {...props} />,
	},
	{
		target: '.topbar .item.letter-colors',
		title: getTranslation('color-letters'),
		content: getTranslation('text-color-letters'),
		tooltipComponent: props => <TooltipComponent prevTab={0} newTab={2} {...props} />,
	},
	{
		target: '.topbar .file',
		title: getTranslation('title-files'),
		content: getTranslation('text-files'),
		tooltipComponent: props => <TooltipComponent prevTab={1} {...props} />,
	},
	{
		target: '.topbar .item.upload-image',
		title: getTranslation('title-image-text'),
		content: getTranslation('text-image-text'),
		tooltipComponent: props => <TooltipComponent prevTab={1} {...props} />,
	},
	{
		target: '.topbar .item.paste-text',
		title: getTranslation('title-paste-text'),
		content: getTranslation('text-paste-text'),
		tooltipComponent: props => <TooltipComponent prevTab={1} {...props} />,
	},
];

export default Tutorial;
