import { Upload } from 'react-feather';
import { useDispatch } from 'react-redux';
import { getTranslation } from '../../i18n/getTranslation';
import { setFileBlocks } from '../../redux/slices/editor';
import { moderateScale } from '../../utils/ResponsiveScale';

const OpenFile = ({ color, className, onFinish }) => {
	const dispatch = useDispatch();

	const openFile = e => {
		const file = e.target.files[0];
		if (!file) return;
		dispatch(setFileBlocks(file));
		onFinish && onFinish();
	};

	return (
		<>
			<input
				style={{ display: 'none' }}
				type='file'
				name='file'
				id='file'
				// agrego esta línea para que si selecciona el mismo archivo, igualmente lo pueda usar
				onClick={e => (e.target.value = null)}
				onChange={openFile}
				accept='.doc,.docx,.rtf'
				multiple={false}
			/>
			<label htmlFor='file' className={className || 'item icon-button open-file'}>
				<Upload color={color || '#26334E'} size={moderateScale(50)} />
				<p>{getTranslation('open-file')}</p>
			</label>
		</>
	);
};

export default OpenFile;
