import { Clipboard } from 'react-feather';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { getTranslation } from '../../i18n/getTranslation';
import { setClipboardData } from '../../redux/slices/editor';
import { moderateScale } from '../../utils/ResponsiveScale';

const PasteText = ({ color, className, onFinish }) => {
	const dispatch = useDispatch();

	const pasteText = async e => {
		try {
			navigator.clipboard
				.readText()
				.then(text => {
					dispatch(setClipboardData(text));
				})
				.catch(err => console.error('Async readText failed with error: "' + err + '"'));
		} catch (err) {
			toast.error(getTranslation('toast-paste-error'));
		}
		onFinish && onFinish();
	};

	return (
		<div className={className || 'item icon-button paste-text'} onClick={pasteText}>
			<Clipboard color={color || '#26334E'} size={moderateScale(50)} />
			<p>{getTranslation('paste-text')}</p>
		</div>
	);
};

export default PasteText;
