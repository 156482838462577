import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ContentState, convertFromRaw, Editor, EditorState, Modifier } from 'draft-js';
import MultiDecorator from 'draft-js-multidecorators';

import letterHighlightDecorator from './draft-decorators/letter-highlight-decorator';
//import wordPictogramDecorator from './draft-decorators/word-pictogram-decorator';
import spaceDecorator from './draft-decorators/space-decorator';
import { useDispatch, useSelector } from 'react-redux';
import { saveEditorState } from '../../redux/slices/editor';

import { emptyDataField } from '../../redux/slices/editor';
import { getFromLocalStorage } from '../../utils/LocalStorage';

const decorator = new MultiDecorator([letterHighlightDecorator, spaceDecorator]);

export default function LectoEditor(props) {
	const [editorState, setEditorState] = useState(() => {
		// get saved editor state and, if exists, set it to current
		const savedData = getFromLocalStorage('editorState');
		if (savedData) return EditorState.createWithContent(convertFromRaw(savedData), decorator);
		return EditorState.createEmpty(decorator);
	});
	const config = useSelector(state => state.config);
	const { fileBlocks, imageData, clipboardData, triggerNewFile } = useSelector(state => state.editor);
	const dispatch = useDispatch();

	const editorRef = useRef(null);

	const saveText = useCallback(() => {
		dispatch(saveEditorState(editorState));
	}, [editorState]);

	useEffect(() => {
		const timeoutId = setTimeout(saveText, 1000);
		return () => clearTimeout(timeoutId);
	}, [saveText]);

	// Cuando se abre un documento en .docx o .rtf
	useEffect(() => {
		if (fileBlocks.data && fileBlocks.data.contentBlocks) {
			const { contentBlocks } = fileBlocks.data;
			const state = ContentState.createFromBlockArray(contentBlocks);
			const newEditorState = EditorState.createWithContent(state, decorator);
			setEditorState(newEditorState);
			dispatch(emptyDataField('fileBlocks'));
		}
	}, [fileBlocks]);

	// Cuando se aprieta "Pegar texto"
	useEffect(() => {
		const selection = editorState.getSelection();
		const contentState = editorState.getCurrentContent();
		if (clipboardData.data && clipboardData.data.length > 0) {
			const text = clipboardData.data;
			const nextContentState = Modifier.insertText(contentState, selection, text);
			const newEditorState = EditorState.push(editorState, nextContentState, 'insert-fragment');
			setEditorState(newEditorState);
			dispatch(emptyDataField('clipboardData'));
		}
	}, [clipboardData]);

	// Cuando se lee el texto de una imagen
	useEffect(() => {
		const selection = editorState.getSelection();
		const contentState = editorState.getCurrentContent();
		if (imageData.data && imageData.data.length > 0) {
			const text = imageData.data.join('\n');
			const nextContentState = Modifier.insertText(contentState, selection, text);
			const newEditorState = EditorState.push(editorState, nextContentState, 'insert-fragment');
			setEditorState(newEditorState);
			dispatch(emptyDataField('imageData'));
		}
	}, [imageData]);

	// Cambiar la configuración "global" del editor, como el text align o el fontSize
	useEffect(() => {
		if (editorRef) {
			const body = document.querySelector('body');
			const editorStyle = editorRef.current.editor.style;
			editorStyle.backgroundColor = config.backgroundColor;
			editorStyle.fontSize = config.fontSize + 'px';
			editorStyle.lineHeight = config.lineSpacing;
			body.style.fontFamily = config.fontFamily;
			body.style.fontFamily = config.fontFamily;

			const textBlocks = document.querySelectorAll('.public-DraftStyleDefault-block');
			if (textBlocks) textBlocks.forEach(block => (block.style.textAlign = config.textAlign));
		}
	}, [config]);

	// Cambiar la configuración "global" del editor, como el text align o el fontSize
	useEffect(() => {
		if (triggerNewFile > 0) {
			setEditorState(() => EditorState.createEmpty(decorator));
		}
	}, [triggerNewFile]);

	useEffect(() => {
		saveText();
	});

	return (
		<Editor
			autoCapitalize='none'
			autoComplete='off'
			autoCorrect='off'
			spellCheck={false}
			ref={editorRef}
			editorState={editorState}
			onChange={setEditorState}
			readOnly={props.readOnly}
		/>
	);
}
