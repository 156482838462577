import { useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

const langToISOLang = {
	es: 'es-ES',
	en: 'en-US',
	fr: 'fr-FR',
	de: 'de-DE',
	it: 'it-IT',
	ca: 'ca-ES',
};

export const useSpeech = () => {
	const { lang } = useSelector(state => state.config);
	const synth = window.speechSynthesis;

	const [isSpeaking, setIsSpeaking] = useState(false);
	const [isPaused, setIsPaused] = useState(false);
	const [isFinished, setIsFinished] = useState(false);

	const speak = ({ text, rate }) => {
		const utterance = new SpeechSynthesisUtterance();
		const voices = synth.getVoices();

		try {
			utterance.voice = voices.filter(item => langToISOLang[lang] === item.lang)[0];
			utterance.lang = langToISOLang[lang];
		} catch (err) {
			toast.error(err.message);
		}

		utterance.text = text;
		utterance.rate = rate;
		synth.speak(utterance);
		setIsSpeaking(true);
		setIsFinished(false);
		setIsPaused(false);
		utterance.onend = () => {
			setIsFinished(true);
		};
	};

	const pause = () => {
		synth.pause();
		setIsSpeaking(false);
		setIsPaused(true);
	};

	const stop = () => {
		synth.cancel();
		setIsSpeaking(false);
	};

	const resume = () => {
		synth.resume();
		setIsPaused(false);
		setIsSpeaking(true);
	};

	const startFromBeginning = ({ text, rate }) => {
		synth.cancel();
		speak({ text, rate });
	};

	return { speak, pause, stop, isSpeaking, isPaused, resume, isFinished, startFromBeginning };
};
