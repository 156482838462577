import { exportToDOCX } from '../../../utils/ExportFile';

import { Camera, Clipboard, FilePlus, File as FileIcon, Save, Upload } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { newFile } from '../../../redux/slices/editor';
import toast from 'react-hot-toast';
import OpenFile from '../../buttons/OpenFile';
import PasteText from '../../buttons/PasteText';
import ReadImageText from '../../buttons/ReadImageText';
import { moderateScale } from '../../../utils/ResponsiveScale';
import { getTranslation } from '../../../i18n/getTranslation';

const File = () => {
	const dispatch = useDispatch();
	const { text } = useSelector(state => state.editor);

	const createNewFile = () => {
		dispatch(newFile());
	};

	return (
		<>
			<div className='file flex center wrap'>
				<div className='item icon-button new-file' onClick={createNewFile}>
					<FileIcon color='#26334E' size={moderateScale(50)} />
					<p>{getTranslation('new-file')}</p>
				</div>
				<div
					className='item icon-button download-file'
					onClick={() => {
						if (text.trim().length > 0) exportToDOCX();
						else toast.error(getTranslation('toast-empty-file-error'));
					}}
				>
					<Save color='#26334E' size={moderateScale(50)} />
					<p>{getTranslation('download-file')}</p>
				</div>
				<OpenFile />
				<ReadImageText />
				<PasteText />
			</div>
		</>
	);
};

export default File;
