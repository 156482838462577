import { CompositeDecorator } from "draft-js";

import findWithRegex from "./find-with-regex";
import { useSelector } from "react-redux";
import { useRef, useState } from "react";
import Pictogram from "../Pictogram";
import { alphabet, normalizeText } from "../../../utils/ColoredLetters";

import {
  getWordPictogramId,
  pictogramsMap,
} from "../../../utils/pictograms-map";

const removeAccent = (word) => {
  const accentMap = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
  };
  return word
    .split("")
    .map((letra) => accentMap[letra] || letra)
    .join("")
    .toString();
};

const updateLocalStorageList = (name, newItem) => {
  const savedList = JSON.parse(localStorage.getItem(name));
  localStorage.setItem(name, JSON.stringify({ ...savedList, ...newItem }));
};

const getPictogram = async (word, lang) => {
  const normalizedWord = word.toLowerCase().trim();
  const savedPictograms =
    JSON.parse(localStorage.getItem(`${lang}-pictogramLinks`)) || {};
  const hasPictogram = savedPictograms[normalizedWord];
  const failedPictograms =
    JSON.parse(localStorage.getItem(`${lang}-failedPictograms`)) || {};
  const hasFailedPictogram = failedPictograms[normalizedWord];

  if (hasFailedPictogram) return null;
  if (hasPictogram)
    return `https://static.arasaac.org/pictograms/${hasPictogram}/${hasPictogram}_300.png`;

  const pictogramId = getWordPictogramId(normalizedWord, lang);
  if (pictogramId) {
    updateLocalStorageList(`${lang}-pictogramLinks`, {
      [normalizedWord]: pictogramId,
    });
    return `https://static.arasaac.org/pictograms/${pictogramId}/${pictogramId}_300.png`;
  }
  updateLocalStorageList(`${lang}-failedPictograms`, {
    [normalizedWord]: true,
  });
  return null;
};

const createLetterHighlightDecorator = (letter, color) => {
  const strategy = (contentBlock, callback, _contentState) => {
    const normalized = normalizeText(letter);
    const lower = normalized.toLowerCase();
    const upper = normalized.toUpperCase();
    findWithRegex(new RegExp(`${lower}|${upper}`, "g"), contentBlock, callback);
  };

  const Component = ({ children, offsetKey }) => {
    const { colors, lang } = useSelector((state) => state.config);
    const letter = children[0].props.text.toLowerCase();
    const normalized = normalizeText(letter);
    const color = colors[normalized];

    const [pictogramUrl, setPictogramUrl] = useState();

    const getWord = (ref) => {
      const prev = getPreviousSpace(ref);
      const next = getNextSpace(ref);
      const word = prev + currentWordRef.current.innerText + next;
      const normalize = removeAccent(word);
      return normalize;
    };

    const [mouseOver, setMouseOver] = useState({ over: false, position: 0 });

    const getPreviousSpace = (ref) => {
      let prevSibling = ref.current.previousElementSibling;
      let word = "";
      while (prevSibling) {
        if (!prevSibling || prevSibling.classList.contains("space")) {
          break;
        }
        word = prevSibling.innerText + "" + word;
        prevSibling = prevSibling.previousElementSibling;
      }
      return word;
    };

    const getNextSpace = (ref) => {
      let nextSibling = ref.current.nextElementSibling;
      let word = "";
      while (nextSibling) {
        if (!nextSibling || nextSibling.classList.contains("space")) {
          break;
        }
        word += nextSibling.innerText + "";
        nextSibling = nextSibling.nextElementSibling;
      }
      return word;
    };

    const currentWordRef = useRef(null);

    const [loadingPictogram, setLoadingPictogram] = useState(false);

    return (
      <span
        onMouseOver={(e) => {
          setMouseOver({
            over: true,
            position: window.outerHeight - e.screenY,
          });
          setLoadingPictogram(true);
          const word = getWord(currentWordRef);
          getPictogram(word, lang).then((url) => {
            setPictogramUrl(url);
            setLoadingPictogram(false);
          });
        }}
        onMouseOut={() => setMouseOver({ over: false })}
        data-offset-key={offsetKey}
        style={{ color }}
        ref={currentWordRef}
      >
        <Pictogram
          positionY={mouseOver.position}
          loading={loadingPictogram}
          hovered={mouseOver.over}
          url={pictogramUrl}
          word={offsetKey}
        />
        {children}
      </span>
    );
  };

  return {
    strategy,
    component: Component,
  };
};

export default new CompositeDecorator(
  alphabet
    .split("")
    .map(([letter, color]) => createLetterHighlightDecorator(letter, color))
);
