import { createSlice } from '@reduxjs/toolkit';
import { getFromLocalStorage, saveToLocalStorage } from '../../utils/LocalStorage';

const slice = createSlice({
	name: 'config',
	initialState: {
		currentTab: 0,
		tutorial: { started: false },
		firstTime: getFromLocalStorage('firstTime') || true,
		viewMobileWelcome: false,
		viewDonationsPopup: false,
	},
	reducers: {
		setCurrentTab: (state, action) => {
			state.currentTab = action.payload;
		},
		setTutorialStarted: (state, action) => {
			state.tutorial.started = action.payload;
		},
		setFirstTime: (state, action) => {
			state.firstTime = false;
			saveToLocalStorage('firstTime', 'false');
		},
		setViewMobileWelcome: (state, action) => {
			state.viewMobileWelcome = action.payload;
		},
		setViewDonationsPopup: (state, action) => {
			state.viewDonationsPopup = action.payload;
		},
	},
});

export const { setCurrentTab, setTutorialStarted, setFirstTime, setViewMobileWelcome, setViewDonationsPopup } =
	slice.actions;

export default slice.reducer;
