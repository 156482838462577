import { useDispatch } from 'react-redux';
import Logo from '../assets/logos/full-logo.png';
import { setViewMobileWelcome } from '../redux/slices/components';
import OpenFile from './buttons/OpenFile';
import PasteText from './buttons/PasteText';
import ReadImageText from './buttons/ReadImageText';

const MobileWelcome = () => {
	const dispatch = useDispatch();

	const closeWelcome = () => {
		dispatch(setViewMobileWelcome());
	};

	return (
		<div className='mobile-welcome-container flex column'>
			<div className='header flex center'>
				<img src={Logo} alt='Logo LectO' />
			</div>
			<div className='content'>
				<div className='texts flex center column'>
					<p className='text'>
						En celulares y tablets, LectO es de solo lectura. Puede subir texto de un archivo, de una imagen o
						pegándolo.
					</p>
					<p className='text'>Para poder editar el texto, por favor ingrese desde una computadora.</p>
				</div>
				<div className='buttons flex column center'>
					<ReadImageText className='button flex center column' color='#366DE1' onFinish={closeWelcome} />
					<PasteText className='button flex center column' color='#366DE1' onFinish={closeWelcome} />
					<OpenFile className='button flex center column' color='#366DE1' onFinish={closeWelcome} />
				</div>
			</div>
		</div>
	);
};

export default MobileWelcome;
