import React from 'react';
import { CompositeDecorator } from 'draft-js';

import findWithRegex from './find-with-regex';
import { useSelector } from 'react-redux';

const strategy = (contentBlock, callback, _contentState) => {
	findWithRegex(/[^a-zA-Z0-9|á|é|í|ó|ú|ñ|Á|É|Í|Ó|Ú|Ñ]+/gu, contentBlock, callback);
};

const Component = ({ children, decoratedText }) => {
	const { colors } = useSelector(state => state.config);
	const letter = children[0].props.text.trim();
	const color = colors[letter];

	return (
		<span className='space' style={{ color }}>
			{children}
		</span>
	);
};

export default new CompositeDecorator([{ strategy, component: Component }]);
