import Popup from '../Popup';
import LetterColorConfig from '../../components/LetterColorConfig';
import ColorButton from '../ColorButton';
import { useDispatch, useSelector } from 'react-redux';
import { setAllLettersColor } from '../../redux/slices/config';
import { repaintAllLetters } from '../../utils/ColoredLetters';
import { getTranslation } from '../../i18n/getTranslation';

const KeyboardPopup = ({ open, setOpen }) => {
	const dispatch = useDispatch();
	const { colors } = useSelector(state => state.config);
	const onAllLettersChange = color => {
		dispatch(setAllLettersColor(color.hex));
		repaintAllLetters();
	};
	return (
		<Popup visible={open} close={() => setOpen(false)} classes='popup-letters'>
			<div className='close-btn' onClick={() => setOpen(false)}>
				X
			</div>
			<div className='keyboard-base'>
				<div className='key inactive'>~</div>
				<LetterColorConfig letter='1' />
				<LetterColorConfig letter='2' />
				<LetterColorConfig letter='3' />
				<LetterColorConfig letter='4' />
				<LetterColorConfig letter='5' />
				<LetterColorConfig letter='6' />
				<LetterColorConfig letter='7' />
				<LetterColorConfig letter='8' />
				<LetterColorConfig letter='9' />
				<LetterColorConfig letter='0' />
				<div className='key inactive'>-</div>
				<div className='key inactive'>+</div>
				<div className='key delete  inactive'>Del</div>
				<div className='key tab inactive'>Tab</div>
				<LetterColorConfig letter='q' />
				<LetterColorConfig letter='w' />
				<LetterColorConfig letter='e' />
				<LetterColorConfig letter='r' />
				<LetterColorConfig letter='t' />
				<LetterColorConfig letter='y' />
				<LetterColorConfig letter='u' />
				<LetterColorConfig letter='i' />
				<LetterColorConfig letter='o' />
				<LetterColorConfig letter='p' />
				<div className='key inactive'>´</div>
				<div className='key inactive'>+</div>
				<div className='key backslash inactive'>\</div>
				<div className='key capslock inactive'>Caps Lock</div>
				<LetterColorConfig letter='a' />
				<LetterColorConfig letter='s' />
				<LetterColorConfig letter='d' />
				<LetterColorConfig letter='f' />
				<LetterColorConfig letter='g' />
				<LetterColorConfig letter='h' />
				<LetterColorConfig letter='j' />
				<LetterColorConfig letter='k' />
				<LetterColorConfig letter='l' />
				<LetterColorConfig letter='ñ' />
				<div className='key inactive'>'</div>
				<div className='key return inactive'>Enter</div>
				<div className='key leftshift inactive'>Shift</div>
				<LetterColorConfig letter='z' />
				<LetterColorConfig letter='x' />
				<LetterColorConfig letter='c' />
				<LetterColorConfig letter='v' />
				<LetterColorConfig letter='b' />
				<LetterColorConfig letter='n' />
				<LetterColorConfig letter='m' />
				<LetterColorConfig letter=',' />
				<LetterColorConfig letter='.' />
				<div className='key inactive'>/</div>
				<div className='key rightshift inactive'>Shift</div>
				<div className='key leftctrl inactive'>Ctrl</div>
				<div className='key inactive'>Win</div>
				<div className='key command inactive'>Alt</div>
				<div className='key space inactive'>Space</div>
				<div className='key command inactive'>Alt</div>
				<div className='key inactive'>Win</div>
				<div className='key inactive'>Ctrl</div>
				<div className='key inactive'>Fn</div>
			</div>

			<ColorButton
				text={getTranslation('change-all-colors')}
				className='change-all-letters'
				onColorChange={onAllLettersChange}
				currentLetterColor={colors[0]}
				changeColor={false}
				left
			/>
		</Popup>
	);
};

export default KeyboardPopup;
