import { useCallback, useEffect, useMemo, useState } from 'react';
import Colors from './tabs/Colors';
import File from './tabs/File';
import HomeTab from './tabs/Home';

import LogoLectO from '../../assets/logos/logo-l.png';
import Info from './tabs/Info';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentTab, setViewDonationsPopup } from '../../redux/slices/components';
import DonationsPopup from '../popups/DonationsPopup';
import { AnimatePresence, motion } from 'framer-motion';
import { getTranslation } from '../../i18n/getTranslation';

const Topbar = ({ textBox }) => {
	const { lang } = useSelector(state => state.config);
	const dispatch = useDispatch();

	const [tabs, setTabs] = useState([]);

	useEffect(() => {
		setTabs([
			{ tabId: 'general', tabName: getTranslation('general'), component: <HomeTab textBox={textBox} /> },
			{ tabId: 'colors', tabName: getTranslation('colors'), component: <Colors /> },
			{ tabId: 'file', tabName: getTranslation('file'), component: <File /> },
			{ tabId: 'info', tabName: getTranslation('info'), component: <Info /> },
		]);
	}, [lang]);

	const { currentTab, viewDonationsPopup } = useSelector(state => state.components);

	const changeToTab = tabNumber => {
		// si toca la actual, se cierra la topbar
		const tabToChange = currentTab === tabNumber ? -1 : tabNumber;
		dispatch(setCurrentTab(tabToChange));
	};

	const setDonationPopup = value => {
		dispatch(setViewDonationsPopup(value));
	};

	return (
		<div className={`topbar ${currentTab > 0 ? 'active-tab' : ''}`}>
			<div className='tabs'>
				<a href='https://lecto.app' target='_blank' rel='noopener noreferrer'>
					<img className='logo' src={LogoLectO} alt='Logo LectO' />
				</a>
				{tabs.map((tab, i) => (
					<div className={`tab ${currentTab === i ? 'active' : ''}`} key={tab.tabId} onClick={() => changeToTab(i)}>
						{tab.tabName}
					</div>
				))}

				<div className='donate-button' onClick={() => setDonationPopup(true)}>
					{getTranslation('support-us')}
				</div>
			</div>

			<AnimatePresence initial={false}>
				{tabs[currentTab] && (
					<motion.div
						initial={{ height: 0 }}
						animate={{ height: 'auto' }}
						exit={{ height: 0 }}
						transition={{ duration: 0.3 }}
						className='content-container'
					>
						<motion.div
							className='content'
							initial={{ opacity: 1 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.1 }}
						>
							{tabs[currentTab].component}
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>

			<DonationsPopup open={viewDonationsPopup} setOpen={setDonationPopup} />
		</div>
	);
};
export default Topbar;
