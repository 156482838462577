import store from '../redux/store';

import spanishJSON from './lang/spanish.json';
import englishJSON from './lang/english.json';
import italianJSON from './lang/italian.json';
import catalanJSON from './lang/catalan.json';
import frenchJSON from './lang/french.json';
import galesJSON from './lang/gales.json';
import deutschJSON from './lang/deutsch.json';
import euskaraJSON from './lang/euskara.json';

const languagesMap = {
	es: spanishJSON,
	en: englishJSON,
	it: italianJSON,
	ca: catalanJSON,
	fr: frenchJSON,
	ga: galesJSON,
	de: deutschJSON,
	eu: euskaraJSON,
};

export const getTranslation = key => {
	const currentLanguage = store.getState().config.lang.split('-')[0] || 'es';
	return languagesMap[currentLanguage][key] || 'no-translation-found';
};
