import { useEffect, useState } from "react";
import { ExternalLink } from "react-feather";
import { useSelector } from "react-redux";
import { getTranslation } from "../../i18n/getTranslation";
import Popup from "../Popup";

const ThanksLink = ({ link, name, text, special }) => {
  return (
    <div className={`link-container ${special && "special"}`}>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="link flex"
      >
        <ExternalLink size={16} color="#fff" />
        <span>{name}</span>
      </a>
      <p>{text}</p>
    </div>
  );
};

const ThanksPopup = ({ open, setOpen }) => {
  const { lang } = useSelector((state) => state.config);
  const [allThanks, setAllThanks] = useState([]);

  useEffect(() => {
    setAllThanks([
      {
        name: "Disfam",
        text: getTranslation("thanks-disfam"),
        link: "https://www.disfam.org/",
      },
      {
        name: "ARASAAC",
        text: getTranslation("thanks-arasaac"),
        link: "https://arasaac.org/",
      },
      {
        name: "OpenDyslexic",
        text: getTranslation("thanks-font"),
        link: "https://opendyslexic.org/",
      },
      {
        name: "Feather Icons",
        text: getTranslation("thanks-icons"),
        link: "https://feathericons.com/",
      },
    ]);
  }, [lang]);

  return (
    <Popup
      classes="thanks-popup custom-scroll"
      visible={open}
      close={() => setOpen(false)}
    >
      <h1>{getTranslation("thanks")}</h1>
      <div className="links">
        {allThanks.map((item) => (
          <ThanksLink
            key={item.name}
            link={item.link}
            name={item.name}
            text={item.text}
          />
        ))}
      </div>
    </Popup>
  );
};

export default ThanksPopup;
