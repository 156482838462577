import { createSlice } from '@reduxjs/toolkit';
import { alphabet } from '../../utils/ColoredLetters';
import { getFromLocalStorage, saveToLocalStorage } from '../../utils/LocalStorage';

const getDefaultLanguage = () => {
	const navLang =
		window.navigator.language || navigator.language || navigator.userLanguage || window.navigator.userLanguage || 'es';
	return navLang.split('-')[0];
};

const initialState = {
	colors: {},
	backgroundColor: '#fff',
	fontSize: 24,
	lineSpacing: 1,
	fontFamily: 'Open Dyslexic',
	voiceSpeed: 1,
	textAlign: 'left',
	lang: getDefaultLanguage(),
};

const loadInitialState = () => {
	let loaded = {};
	Object.keys(initialState).forEach(key => {
		loaded[key] = getFromLocalStorage(key) || initialState[key];
	});
	return loaded;
};

const slice = createSlice({
	name: 'config',
	initialState: loadInitialState(),
	reducers: {
		setColor: (state, action) => {
			const { letter, color } = action.payload;
			state.colors[letter] = color;
			saveToLocalStorage('colors', state.colors);
		},
		setAllLettersColor: (state, action) => {
			alphabet.split('').forEach(letter => (state.colors[letter] = action.payload));
			saveToLocalStorage('colors', state.colors);
		},
		setConfigValue: (state, action) => {
			const { field, value } = action.payload;
			state[field] = value;
			saveToLocalStorage(field, value);
		},
		incrementFontSize: (state, action) => {
			//const current = localStorage.getItem('fontSize');
			state.fontSize = 1 + 1;
			//saveToLocalStorage('fontSize', current + 1);
		},
		decrementFontSize: (state, action) => {
			const current = getFromLocalStorage('fontSize');
			state.fontSize = current - 1;
			//saveToLocalStorage('fontSize', current - 1);
		},
	},
});

export const { setColor, setAllLettersColor, setConfigValue } = slice.actions;

export default slice.reducer;
