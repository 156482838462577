//import { useEffect } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import MouseTooltip from 'react-sticky-mouse-tooltip';

const Pictogram = ({ hovered, url, word, loading, positionY }) => {
	const [offsetY, setOffsetY] = useState(-15);

	const checkOffset = useCallback(() => {
		if (!positionY) return;
		const threshold = 300;
		if (positionY < threshold) {
			return setOffsetY(-175);
		}
		return setOffsetY(-15);
	}, [positionY]);

	useEffect(() => {
		checkOffset();
	}, [checkOffset]);

	return (
		<>
			{hovered && (
				<MouseTooltip offsetY={offsetY} style={{ margin: 0, padding: 0 }} visible={hovered && !!url} offsetX={-65}>
					<div className='pictogram' style={{ margin: 0, padding: 0 }}>
						{loading ? <div className='loader' /> : <img src={url} alt={`Pictogram for ${word}`} />}
					</div>
				</MouseTooltip>
			)}
		</>
	);
};

export default Pictogram;
