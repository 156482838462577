import { useState } from 'react';
import { ColorPicker, useColor } from 'react-color-palette';
import { useSelector } from 'react-redux';
import 'react-color-palette/lib/css/styles.css';

const ColorButton = props => {
	const { fontFamily } = useSelector(state => state.config);
	const [showColorpicker, setShowColorPicker] = useState(false);
	const { currentLetterColor, text, onColorChange, changeColor } = props;

	const topStyle = {
		top: '-200%',
		left: '50%',
		transform: 'translateX(-50%)',
	};

	const popover = {
		position: 'absolute',
		zIndex: '2',
		...(props.left ? topStyle : { top: '100%' }),
	};
	const cover = {
		position: 'fixed',
		top: '0px',
		left: '0px',
		width: '100vw',
		height: '100vh',
	};
	const color = {
		color: changeColor && currentLetterColor,
		borderColor: changeColor && currentLetterColor,
		fontFamily,
	};

	const handleClick = () => setShowColorPicker(true);
	const handleClose = () => setShowColorPicker(false);

	const [color2, setColor2] = useColor('hex', currentLetterColor || '#fff');

	return (
		<div className={`color-button ${props.className}`} style={{ position: 'relative' }}>
			{props.children ? (
				<div className='icon-button' onClick={handleClick}>
					{props.children}
				</div>
			) : (
				<button onClick={handleClick} style={color}>
					{text}
				</button>
			)}
			{showColorpicker && (
				<div className='color-picker-container'>
					<div style={cover} onClick={handleClose} className='color-cover' />
					<div style={popover}>
						<ColorPicker
							className='color-picker'
							width={230}
							height={120}
							color={color2}
							onChange={c => {
								setColor2(c);
								onColorChange(c);
							}}
							hideHSB
							hideHEX
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default ColorButton;
