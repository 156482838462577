import { useEffect, useRef } from 'react';
import './styles/main.scss';
import LectoEditor from './components/LectoEditor/LectoEditor';
import Topbar from './components/topbar/Topbar';
import { Toaster } from 'react-hot-toast';
import Tutorial from './components/Tutorial';
import FirstTimePopup from './components/FirstTimePopup';
import { useSelector } from 'react-redux';
import MobileWelcome from './components/MobileWelcome';
import ReactGA from 'react-ga';
import Popup from './components/Popup';

const toastOptions = {
	style: {
		zIndex: 1,
		maxWidth: '90vw',
	},
	success: {
		style: {
			boxShadow: '0 0 0 3px #43C821',
			color: '#43C821',
		},
		iconTheme: {
			primary: '#43C821',
			secondary: '#fff',
		},
		duration: 2500,
	},
	loading: {
		style: {
			boxShadow: '0 0 0 3px #EEA83D',
			color: '#EEA83D',
		},
		iconTheme: {
			primary: '#EEA83D',
			secondary: '#fff',
		},
	},
	error: {
		style: {
			boxShadow: '0 0 0 3px #F44949',
			color: '#F44949',
		},
		iconTheme: {
			primary: '#F44949',
			secondary: '#fff',
		},
	},
};

const checkUserIsMobile = () => {
	return window.matchMedia('(any-hover: none)').matches;
};

function App() {
	const isMobile = checkUserIsMobile();
	const textBox = useRef();

	const { firstTime, viewMobileWelcome } = useSelector(state => state.components);
	const isFirstTime = JSON.parse(firstTime);

	useEffect(() => {
		if (firstTime === false) {
			ReactGA.initialize('G-2X41GZ4TKY');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}, [firstTime]);

	return (
		<div className='App'>
			{viewMobileWelcome && <MobileWelcome />}
			{isFirstTime && <FirstTimePopup isMobile={checkUserIsMobile} />}
			<Topbar textBox={textBox} />
			<LectoEditor readOnly={isMobile} />
			<Toaster position='bottom-right' reverseOrder={false} toastOptions={toastOptions} />
			<Tutorial isMobile={checkUserIsMobile} />
		</div>
	);
}

export default App;
