import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setColor } from '../redux/slices/config';
import { repaintLetter } from '../utils/ColoredLetters';
import ColorButton from './ColorButton';

const ButtonExample = ({ letter }) => {
	const dispatch = useDispatch();
	const { colors } = useSelector(state => state.config);

	const currentLetterColor = colors[letter] || '#000';

	const onColorChange = color => {
		dispatch(setColor({ letter, color: color.hex }));
		repaintLetter(letter, color.hex);
	};

	return (
		<>
			<ColorButton
				className='key'
				onColorChange={onColorChange}
				currentLetterColor={currentLetterColor}
				text={letter}
				changeColor={true}
			/>
		</>
	);
};

export default ButtonExample;
