import { configureStore } from '@reduxjs/toolkit';
import config from './slices/config';
import editor from './slices/editor';
import components from './slices/components';

export default configureStore({
	reducer: { config, editor, components },
	middleware: getDefaultMiddleware => {
		return getDefaultMiddleware({
			serializableCheck: false,
		});
	},
});
