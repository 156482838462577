import Popup from '../Popup';

import LogoMP from '../../assets/logos/logo-mp.png';
import LogoPayPal from '../../assets/logos/logo-paypal.png';
import { getTranslation } from '../../i18n/getTranslation';
const DonationsPopup = ({ open, setOpen }) => {
	return (
		<Popup classes='donate-popup custom-scroll' visible={open} close={() => setOpen(false)}>
			<div className='buttons flex center'>
				<div className='big-button flex center column'>
					<p className='title'>{getTranslation('mp-donation-text')}</p>
					<img src={LogoMP} alt='Logo de MercadoPago' />
					<a className='button' href='https://cafecito.app/LectO' target='_blank' rel='noopener noreferrer'>
						{getTranslation('cafecito-donations')}
					</a>
				</div>
				<div className='big-button flex center column'>
					<p className='title'>{getTranslation('pp-donation-text')}</p>
					<img src={LogoPayPal} alt='Logo de Paypal' />
					<a className='button' href='https://ko-fi.com/LectO' target='_blank' rel='noopener noreferrer'>
						{getTranslation('kofi-donations')}
					</a>
				</div>
			</div>
		</Popup>
	);
};

export default DonationsPopup;
