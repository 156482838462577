import { Camera } from 'react-feather';
import { useDispatch } from 'react-redux';
import { getTranslation } from '../../i18n/getTranslation';
import { addImageData } from '../../redux/slices/editor';
import { moderateScale } from '../../utils/ResponsiveScale';

const ReadImageText = ({ className, color, onFinish }) => {
	const dispatch = useDispatch();

	const openImage = e => {
		const files = e.target.files;
		if (files.length <= 0) return;
		dispatch(addImageData(files));
		onFinish && onFinish();
	};

	return (
		<>
			<input
				style={{ display: 'none' }}
				type='file'
				name='image'
				id='image'
				// agrego esta línea para que si selecciona el mismo archivo, igualmente lo pueda usar
				onClick={e => (e.target.value = null)}
				onChange={openImage}
				accept='.jpg,.jpeg,.png'
				multiple
			/>
			<label htmlFor='image' className={className || 'item icon-button upload-image'}>
				<Camera color={color || '#26334E'} size={moderateScale(50)} />
				<p>{getTranslation('read-image-text')}</p>
			</label>
		</>
	);
};

export default ReadImageText;
