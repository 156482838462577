import store from '../redux/store';

export const alphabet = 'abcdefghijklmnñopqrstuvwxyz.,:_-1234567890';

export const paintLetter = letter => {
	const { config } = store.getState();

	const hasColor = config.colors[letter.toLowerCase()];

	const span = document.createElement('span');
	span.style.color = hasColor || '#000';
	span.classList.add('letter');
	span.innerHTML = letter;
	return span;
};

export const repaintLetter = (letter, color) => {
	const letters = document.querySelectorAll('.letter');

	letters.forEach(letterSpan => {
		const sameLetter = letterSpan.textContent.toLowerCase() === letter.toLowerCase();
		if (sameLetter) {
			letterSpan.style.color = color;
		}
	});
};

export const repaintAllLetters = () => {
	const { config } = store.getState();
	const letters = document.querySelectorAll('.letter');

	letters.forEach(letterSpan => {
		const stateColor = config.colors[letterSpan.textContent.toLowerCase()];
		if (stateColor !== letterSpan.style.color) letterSpan.style.color = stateColor || '#000';
	});
};

export const normalizeText = text => {
	return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
