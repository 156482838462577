import { useEffect, useState } from 'react';
import {
	AlignCenter,
	AlignLeft,
	AlignRight,
	ArrowLeftCircle,
	PauseCircle,
	PlayCircle,
	StopCircle,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useSpeech } from '../../../hooks/useSpeech';
import { getTranslation } from '../../../i18n/getTranslation';
import { setConfigValue } from '../../../redux/slices/config';
import { fontFamilies } from '../../../utils/Fonts';

const FontSettings = ({ textBox }) => {
	const dispatch = useDispatch();
	const { fontSize, fontFamily, lineSpacing, voiceSpeed, lang } = useSelector(state => state.config);
	const config = useSelector(state => state.config);
	const { text } = useSelector(state => state.editor);
	const { speak, isSpeaking, isPaused, pause, stop, resume, isFinished, startFromBeginning } = useSpeech();

	const [acceptedFonts, setAcceptedFonts] = useState([]);

	const addFont = font => {
		if (acceptedFonts.includes(font)) return;
		setAcceptedFonts(prev => [...prev, font]);
	};

	const iconSize = 50;

	useEffect(() => {
		document.fonts.ready.then(userFonts => {
			fontFamilies.forEach(fontFamily => {
				const hasFont = userFonts.check(`${fontSize}px ${fontFamily}`);
				if (hasFont || fontFamily === 'Open Dyslexic') return addFont(fontFamily);
			});
		});
	}, []);

	const incrementField = (field, amount) => {
		const value = Math.round((+config[field] + amount) * 10) / 10;
		dispatch(setConfigValue({ field, value }));
	};
	const decrementField = (field, amount) => {
		if (config[field] <= 1) return;
		const value = Math.round((+config[field] - amount) * 10) / 10;
		dispatch(setConfigValue({ field, value }));
	};

	const onFontSizeChange = e => dispatch(setConfigValue({ field: 'fontSize', value: e.target.value }));
	const onFontFamilyChange = e => dispatch(setConfigValue({ field: 'fontFamily', value: e.target.value }));
	const onLineSpacingChange = e => dispatch(setConfigValue({ field: 'lineSpacing', value: e.target.value }));
	const onTextAlignChange = textAlign => dispatch(setConfigValue({ field: 'textAlign', value: textAlign }));
	const onVoiceSpeedChange = e => dispatch(setConfigValue({ field: 'voiceSpeed', value: e.target.value }));
	const onLanguageChange = e => dispatch(setConfigValue({ field: 'lang', value: e.target.value }));

	const readAlloud = text => {
		stop();
		speak({ text, rate: voiceSpeed });
	};

	return (
		<>
			<div className='text-config flex wrap center'>
				<div className='item input-group'>
					<label htmlFor=''>{getTranslation('size')}:</label>
					<div className='buttons-input'>
						<button className='btn-left' onClick={() => decrementField('fontSize', 1)}>
							-
						</button>
						<input onChange={onFontSizeChange} style={{ fontFamily }} type='number' name='fontsize' value={fontSize} />
						<button className='btn-right' onClick={() => incrementField('fontSize', 1)}>
							+
						</button>
					</div>
				</div>

				<div className='item input-group'>
					<label htmlFor='linespacing'>{getTranslation('line-spacing')}:</label>
					<div className='buttons-input'>
						<button className='btn-left' onClick={() => decrementField('lineSpacing', 0.1)}>
							-
						</button>
						<input
							onChange={onLineSpacingChange}
							style={{ fontFamily }}
							type='number'
							min={1}
							step='0.1'
							name='linespacing'
							value={lineSpacing}
						/>
						<button className='btn-right' onClick={() => incrementField('lineSpacing', 0.1)}>
							+
						</button>
					</div>
				</div>

				<div className='item input-group'>
					<label htmlFor=''>{getTranslation('font')}:</label>

					<select
						name=''
						id=''
						onChange={onFontFamilyChange}
						value={fontFamily}
						style={{ fontFamily, color: '#26334E' }}
					>
						{acceptedFonts.map(font => (
							<option key={font} value={font} style={{ fontFamily: font }}>
								{font}
							</option>
						))}
					</select>
				</div>

				<div className='item input-group'>
					<label htmlFor=''>{getTranslation('language')}:</label>

					<select name='' id='' value={lang} onChange={onLanguageChange} style={{ fontFamily, color: '#26334E' }}>
						<option value='es'>Español</option>
						<option value='en'>English</option>
						<option value='de'>Deutsch</option>
						<option value='it'>Italiano</option>
						<option value='ca'>Català</option>
						<option value='fr'>Français</option>
						<option value='eu'>Euskara</option>
					</select>
				</div>
			</div>

			<div className='item icons f1 align'>
				<div className='button-hover flex center' onClick={() => onTextAlignChange('left')}>
					<AlignLeft size={iconSize} color='#26334E' />
				</div>
				<div className='button-hover flex center' onClick={() => onTextAlignChange('center')}>
					<AlignCenter size={iconSize} color='#26334E' />
				</div>
				<div className='button-hover flex center' onClick={() => onTextAlignChange('right')}>
					<AlignRight size={iconSize} color='#26334E' />
				</div>
			</div>

			<div className='tts'>
				<div className='item'>
					<div className='input-group voice-speed'>
						<label htmlFor=''>{getTranslation('voice-speed')}:</label>
						<input
							type='range'
							name='speed'
							id=''
							onChange={onVoiceSpeedChange}
							min='0.5'
							max='2'
							step='0.1'
							defaultValue={voiceSpeed}
						/>
					</div>

					<div className='icons'>
						<ArrowLeftCircle
							size={iconSize}
							color='#26334E'
							onClick={() => startFromBeginning({ text, rate: voiceSpeed })}
						/>
						{isSpeaking && !isFinished ? (
							<PauseCircle size={iconSize} color='#26334E' onClick={pause} />
						) : (
							<PlayCircle size={iconSize} color='#26334E' onClick={() => (isPaused ? resume() : readAlloud(text))} />
						)}
						<StopCircle size={iconSize} color='#26334E' onClick={stop} />
					</div>
				</div>
			</div>
		</>
	);
};

export default FontSettings;
