export const fontFamilies = [
	'Arial',
	'Calibri',
	'Comic Sans MS',
	'Impact',
	'Open Dyslexic',
	'OpenSans',
	'Times New Roman',
	'Verdana',
];
