import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFirstTime, setTutorialStarted, setViewMobileWelcome } from '../redux/slices/components';

import logoLectO from '../assets/logos/full-logo.png';
import { getTranslation } from '../i18n/getTranslation';

const FirstTimePopup = props => {
	const dispatch = useDispatch();
	const [checked, setChecked] = useState(false);

	const startWithTutorial = () => {
		dispatch(setFirstTime());
		dispatch(setTutorialStarted(true));
	};

	const startWithoutTutorial = () => {
		if (props.isMobile()) dispatch(setViewMobileWelcome(true));
		dispatch(setFirstTime());
	};

	return (
		<div className='first-time-container flex column center'>
			<div className='welcome flex column center'>
				<h1 className='title'>{getTranslation('welcome-to')}</h1>
				<img className='logo' src={logoLectO} alt='Logo LectO Completo' />
			</div>

			<label className='privacy-policy'>
				<a href="https://lecto.app/en/privacy" target="_blank" rel="noopener noreferrer">
				  {getTranslation('accept-privacy-policy')}
				</a>
				<input type='checkbox' onChange={e => setChecked(e.target.checked)} />
				<span className='checkmark'></span>
			</label>

			{checked && (
				<div className='tutorial'>
					<p className='text'>{getTranslation('watch-tutorial-question')}</p>
					<div className='buttons'>
						<button className='btn btn-no' onClick={startWithoutTutorial}>
							{getTranslation('watch-tutorial-no')}
						</button>
						<button className='btn btn-yes' onClick={startWithTutorial}>
							{getTranslation('watch-tutorial-yes')}
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default FirstTimePopup;
