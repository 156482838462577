import Resizer from 'react-image-file-resizer';

export const resizeImage = image => {
	return new Promise(resolve => {
		Resizer.imageFileResizer(
			// file to resize
			image,
			// max width (mantains aspect-ratio)
			600,
			// max height (mantains aspect-ratio)
			600,
			// file type
			'PNG',
			// quality
			100,
			// rotation
			0,
			// final uri
			uri => {
				resolve(uri);
			},
			// format to "save" file
			'file'
		);
	});
};
